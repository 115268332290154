import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ffc71256"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "default-header customer-details-header" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "company-info--wrapper" }
const _hoisted_4 = { class: "company-info" }
const _hoisted_5 = { class: "company-info--title" }
const _hoisted_6 = { class: "company-info--description" }
const _hoisted_7 = { class: "company-info--description" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "company-info--status" }
const _hoisted_11 = { class: "buttons--wrapper" }
const _hoisted_12 = { class: "actions" }
const _hoisted_13 = { class: "company-info--last-update" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.showMergeCustomerAccounts)
      ? (_openBlock(), _createBlock($setup["MergeCustomerAccounts"], {
          key: 0,
          companyId: $props.customerDetails.id,
          companyName: $props.customerDetails.fullName,
          onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.showMergeCustomerAccounts = false))
        }, null, 8, ["companyId", "companyName"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["CompanyEditableLogo"], {
          customerId: $props.customerDetails.id,
          logoId: $props.customerDetails.logoId,
          onLogoUpdated: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('logoUpdated', $event)))
        }, null, 8, ["customerId", "logoId"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString($props.customerDetails.fullName), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString($setup.formatCustomerNumber($props.customerDetails.number)), 1),
          _createElementVNode("div", _hoisted_7, [
            ($props.customerDetails.companyAccountId.industry)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString($props.customerDetails.companyAccountId.industry), 1))
              : _createCommentVNode("", true),
            ($props.customerDetails.companyAccountId.size)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString($props.customerDetails.companyAccountId.size) + " People", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, _toDisplayString($setup.getCustomerStatus($props.customerDetails?.status, $props.customerDetails.isDeleted)), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode($setup["MembersSection"], {
            editCustomersAllowed: $setup.membersEditAllowedRole,
            editInternalMembersAllowed: $setup.membersEditAllowedRole,
            internalMembers: $setup.internalMembers,
            activeMembers: $props.customerDetails.teamId?.members,
            invitedMembers: $props.customerDetails.teamId.invites,
            teamId: $props.customerDetails.teamId.id,
            showStaticMembers: "",
            disableStaticMembers: $setup.disableStaticMembers
          }, null, 8, ["editCustomersAllowed", "editInternalMembersAllowed", "internalMembers", "activeMembers", "invitedMembers", "teamId", "disableStaticMembers"]),
          _createVNode($setup["NDropdown"], {
            class: "main-dropdown quote-details-header-actions",
            show: $setup.showActions,
            "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (($setup.showActions) = $event)),
            options: $setup.actionOptions,
            placement: "bottom-end",
            trigger: "click",
            onSelect: $setup.handleActionOptionSelect
          }, {
            default: _withCtx(() => [
              _createVNode($setup["NButton"], {
                class: "extra-small-button n-button-actions n-button-with-arrow",
                round: "",
                ghost: "",
                disabled: $setup.actionOptions.length === 0
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Actions"),
                  _createVNode($setup["ChevronDown"], { color: "currentColor" })
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }, 8, ["show", "options"])
        ]),
        _createElementVNode("span", _hoisted_13, "Saved at " + _toDisplayString($setup.dayjs($props.customerDetails.updatedAt).format("HH:mm")), 1)
      ])
    ])
  ]))
}